@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Limelight&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Limelight&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

:root {
  --white-color: #fdfdfd !important;
  --black-color: #0d0d0d !important;
  --gray-color: #f0f0f0 !important;
  --green-color: #9fef00 !important;
  --red-color: #ff3e3e !important;
}

body {
  margin: 0;
  text-wrap: balance;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.navbar-links {
  font-family: 'Roboto Mono', monospace;
  font-optical-sizing: auto;
  font-style: normal;
}

* {
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  box-sizing: border-box;
  scrollbar-width: none;
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
}

.limelight-regular {
  font-family: 'Limelight', sans-serif;
  font-weight: 400;
  font-style: normal;
}

* ::-webkit-scrollbar {
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.rt-BaseTabListTrigger:where([data-state='active'], [data-active])::before {
  background-color: var(--black-color);
  height: 4px;
}
